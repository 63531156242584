import moment from "moment";

export const formatDate = (date: string) => {
  return !date ? "" : moment(date).format("MMM DD");
};

export const formatFullDate = (date: string) => {
  return !date ? "" : moment(date).format("DD MMMM YYYY");
};

export const handleSignUpClick = () => {
  window.location.hash = "";
  window.location.hash = "#signup";
};

export const getGuideTextColor = (category: string) => {
  switch (category) {
    case "financial-health":
      return "text-ultraMarine";
    case "getting-mortgage-ready":
      return "text-amber";
    case "buying-a-home":
      return "text-cyan";
    default:
      return "text-black";
  }
};

export const getGuideBgColor = (category: string) => {
  switch (category) {
    case "financial-health":
      return "bg-guides-paleUltraMarine";
    case "getting-mortgage-ready":
      return "bg-guides-paleAmber";
    case "buying-a-home":
      return "bg-guides-paleCyan";
    default:
      return "bg-white";
  }
};
